export default [
  {
    path: '/parceiro/remessas',
    name: 'partner.remessas.index',
    component: () => import('@/views/partner/remessas/pages/Index.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Remessas',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Remessas disponíveis',
        serviceUrl: '/recebiveis/remessas',
        editRouteName: 'remessas.show',
      },
    },
  },
  {
    path: '/parceiro/redescontos',
    name: 'partner.redescontos.index',
    component: () => import('@/views/admin/rediscount/pages/Index.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      // pageTitle: 'Gestão de remessas',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Redescontos',
        serviceUrl: '/recebiveis/cashu/redesconto',
      },
    },
  },
  {
    path: '/parceiro/remessas/:id',
    name: 'partner.remessas.show',
    component: () => import('@/views/partner/remessas/pages/Show.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      navActiveLink: 'partner.remessas.index',
      indexObject: {
        datatableTitle: 'Títulos',
      },
    },
  },
  {
    path: '/parceiro/titulos',
    name: 'partner.titulos.index',
    component: () => import('@/views/partner/titulos/pages/Index.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Títulos',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Títulos',
        serviceUrl: '/recebiveis/titulos',
        editRouteName: 'titulos.show',
      },
    },
  },
  {
    path: '/parceiro/ocorrencias',
    name: 'partner.extract.index',
    component: () => import('@/views/partner/occurrences/pages/Index.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Gestão de ocorrências',
      skipGoToHome: true,
    },
  },
  {
    path: '/parceiro/cadastro',
    name: 'partner.register',
    component: () => import('@/views/common/auth/pages/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Prestador de serviço',
          // icon: require('@/assets/images/provider/login-icon.png'),
        },
        // appLogoGrande: require('@/assets/images/provider/white-bg-cs-logo-fundo-escuro.png'),
        // cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
]
