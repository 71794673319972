import http from './http'

class ExceptionFile {
  index() {
    return http.get(`/recebiveis/importacoes_excecoes`)
  }

  get(id) {
    return http.get(`/recebiveis/importacoes_excecoes/${id}`)
  }

  operations(id) {
    return http.get(`/recebiveis/importacoes_excecoes/${id}/operacoes`)
  }

  baixas(id) {
    return http.get(`/recebiveis/importacoes_excecoes/${id}/baixas`)
  }

  proccess(id) {
    return http.post(`/recebiveis/importacoes_excecoes/${id}/processar`)
  }

  setBaixaType(payload) {
    return http.post(`/recebiveis/importacoes_excecoes/set_tipo_baixa`, payload)
  }

  upload(payload) {
    let formData = new FormData()

    formData.append('arquivo', payload)

    return http.post(`/recebiveis/importacoes_excecoes`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  exportExceptionFiles(payload) {
    return http.get(`/recebiveis/importacoes_excecoes/excel_listagem`, {
      params: payload,
      responseType: 'arraybuffer',
    })
  }

  exportExceptionFilesOperations(id, payload) {
    return http.get(`/recebiveis/importacoes_excecoes/${id}/excel`, {
      params: payload,
      responseType: 'arraybuffer',
    })
  }
}

export default new ExceptionFile()
