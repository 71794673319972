import jwt from '@/auth/jwt/useJwt'
import Auth from '@/services/auth'

export default {
  login({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login({ user: payload })
        .then(async ({ data }) => {
          const { user } = data
          const role = {
            'Admin::User': 'admin',
            'Cedente::User': 'partner',
          }

          jwt.setToken(data.auth_token)

          user.ability = [{ action: `${role[user.type]}-read`, resource: 'user' }]
          user.ability.push({ action: 'read', resource: 'Auth' })

          jwt.setUserData(user)

          commit('auth/SET_USER_DATA', user, { root: true })
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  sendResetPasswordEmail(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.sendResetPasswordEmail(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.userData()
        .then((response) => {
          commit('auth/UPDATE_USER_DATA', response.data, { root: true })
          commit('auth/SET_NEW_LIMIT', response.data.limite_disponivel, { root: true })
          commit('company/SET_CURRENT', response.data, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  newPassword(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.newPassword(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async logout() {
    return jwt.logout()
  },
}
